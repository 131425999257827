import { FormattedMessage, FormattedRelativeTime } from 'react-intl';

import { ChatMemberName } from './ChatMemberName';
import { useUserId, type Message } from '../features/chat/chatSlice';

type ChatListItemLastMessageProps = {
    chatId: string;
    lastMessage?: Message;
};

export const ChatListItemLastMessage = (props: ChatListItemLastMessageProps) => {
    const { chatId, lastMessage } = props;

    const currentUserId = useUserId();

    if (!lastMessage) {
        return null;
    }

    const isOwnMessage = lastMessage.userId === currentUserId;
    const isChatRecipientId = chatId === lastMessage.userId;

    return (
        <div className='ChatListItemLastMessage text-size-12 line-height-125rel text-color-gray ellipsis-2 text-capitalize-first-word margin-top-3'>
            <div>
                {!isChatRecipientId && (
                    <>
                        {isOwnMessage && (
                            <span>
                                <FormattedMessage id='intl-msg:chat.list.lastMessage.you' />
                            </span>
                        )}
                        {!isOwnMessage && (
                            <span>
                                <ChatMemberName userId={lastMessage.userId} chatId={chatId} showLicensePlate={false} />
                            </span>
                        )}

                        <span className='padding-right-15 position-relative'>
                            <span
                                className='rioglyph rioglyph-sphere position-absolute top-5 right-5'
                                style={{ fontSize: '5px' }}
                            />
                        </span>
                    </>
                )}

                <span>
                    <FormattedRelativeTime
                        value={(lastMessage.createdAt - Date.now()) / 1000}
                        numeric='auto'
                        style='long'
                        updateIntervalInSeconds={1}
                    >
                        {value => <>{value}</>}
                    </FormattedRelativeTime>
                </span>
            </div>
            <div>{lastMessage.text}</div>
        </div>
    );
};
