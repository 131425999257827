import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { inIframe } from '@rio-cloud/rio-uikit/DeviceUtils';

import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';
import { FormattedMessage } from 'react-intl';
import { useAppContext } from '../../layout/AppContext';
import { FooterButton } from './FooterButton';

export const Footer = () => {
    const { navigateToFeedback, navigateToHelp } = useAppContext();

    const handleOpenNewTab = () => {
        gaPush({
            category: TRACKING_CATEGORIES.NAVIGATION,
            action: 'Open in new tab',
            label: 'Opened chat in new tab from Livemonitor',
        });
        window.open(window.location.href, '_blank');
    };

    const handleNavigateToFeedback = () => navigateToFeedback('from footer');
    const handleNavigateToHelp = () => navigateToHelp('from footer');

    return (
        <div className='flex-0 bg-lightest border border-top-only border-color-lighter display-flex justify-content-between align-items-center height-25 padding-left-10 position-relative'>
            <div className='display-flex gap-15 text-color-gray'>
                <FooterButton
                    onClick={handleNavigateToHelp}
                    iconName='rioglyph-question-sign'
                    text={<FormattedMessage id='intl-msg:chat.help.title' />}
                />
                <FooterButton
                    onClick={handleNavigateToFeedback}
                    iconName='rioglyph-envelope'
                    text={<FormattedMessage id='intl-msg:chat.feedback.title' />}
                />
            </div>
            {inIframe() && (
                <SimpleTooltip
                    content={<FormattedMessage id='intl-msg:chat.tooltip.openInNewTab' />}
                    placement='top-end'
                    delay={{ show: 1_000, hide: 0 }}
                    popperConfig={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [-5, 0],
                                },
                            },
                            {
                                name: 'arrow',
                                options: {},
                            },
                        ],
                    }}
                >
                    <div
                        className='display-flex align-items-center gap-5 text-color-dark hover-text-color-darkest text-size-12 cursor-pointer padding-x-10'
                        onClick={handleOpenNewTab}
                    >
                        <span className='rioglyph rioglyph-new-window' />
                    </div>
                </SimpleTooltip>
            )}
        </div>
    );
};
