import { FormattedMessage, FormattedPlural } from 'react-intl';
import isEmpty from 'lodash/fp/isEmpty';

import { MessageItem } from '../../features/chat/MessageItem';
import type { Message } from '../../features/chat/chatSlice';

export type BroadcastMessage = Omit<Message, 'userId'> & { recipientCount: number };

type BroadcastMessagesProps = {
    messages?: BroadcastMessage[];
    totalRecipients?: number;
    className?: string;
};

export const BroadcastMessages = (props: BroadcastMessagesProps) => {
    const { messages = [], totalRecipients = 0, className = '' } = props;

    if (isEmpty(messages)) {
        return null;
    }

    return (
        <div className={className}>
            {messages.map(message => (
                <MessageItem
                    key={message.id}
                    chatId={message.channelId}
                    messageId={message.id}
                    showSender={false}
                    senderName={'admin'}
                    messageText={message.text}
                    sendAt={message.createdAt}
                    enableTranslation={false}
                    footer={
                        <span className='margin-right-5'>
                            {message.recipientCount}{' '}
                            <FormattedPlural
                                value={totalRecipients}
                                one={<FormattedMessage id='intl-msg:chat.broadcast.label.recipientsSelected.one' />}
                                other={<FormattedMessage id='intl-msg:chat.broadcast.label.recipientsSelected.other' />}
                            />
                        </span>
                    }
                    hideReadStatus
                />
            ))}
        </div>
    );
};
